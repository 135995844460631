export const jurisdictionList = {
  AB: 'Alberta',
  BC: 'British Columbia',
  FED: 'Federal',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  // NT: 'Northwest Territories', // * Note: Territories disabled on Feb 5 2020 so they'll be hidden from menus (we don't have content anyway)
  NS: 'Nova Scotia',
  // NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Quebec',
  SK: 'Saskatchewan',
  // YT: 'Yukon'
};

export const jurisdictionCodes = Object.keys(jurisdictionList).sort();
export const jurisdictionNames = Object.values(jurisdictionList).sort();

export const getNameByCode = code => jurisdictionList[code];

export const getCodeByName = name =>
  Object.keys(jurisdictionList)
    .map(code => {
      if (jurisdictionList[code] === name) {
        return code;
      } else {
        return false;
      }
    })
    .filter(Boolean)[0];

export const sortCodesByName = codes => {
  // This function takes an array of codes and sorts them based on their corresponding names,
  // which will result in a more natural sort order for the end-user.
  const sortedCodes = codes
    .map(code => getNameByCode(code)) // Map to names
    .sort() // Sort the names
    .map(name => getCodeByName(name)); // Map back to codes

  return sortedCodes;
};

// For use in pulldowns
export const jurisdictionOptionsList = jurisdictionCodes.map(code => ({
  id: code,
  name: getNameByCode(code),
}));

export const filterJurisdictionsBySubscription = (user, jurs) => {
  if (user.account.selectedJurisdictions.length === 0) {
    // Allow everything
    return jurs;
  }

  // Need to filter based on what's allowed
  return jurs.filter(j => user.account.selectedJurisdictions.includes(j));
};
